header {
  height: 100px;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 100;
  background: rgba(24, 24, 24, 0.1);
  backdrop-filter: blur(20px);
  padding: 0 40px;
  display: flex;
  align-items: flex-end;
  transition: var(--transition);
}

.header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #fff;
  transition: var(--transition);
}
header button{
  max-width: 125px;
}
header.scrolled {


}

header.scrolled .header {
  border-bottom-color: #fff;
}

.header-logo {
  width: 140px;
  height: auto;
  transition: transform .5s ease;
}

.header-separator {
  margin: 0 24px;
  height: 28px;
  width: 1px;
  background-color: var(--divider);
}

.header-right, .header-left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
nav {
  margin: 0 -12px;
}
nav ul {
  list-style: none;
  display: flex;
  font-size: 14px;
  color: var(--muted-color)
}

nav ul li {
  padding-bottom: 0;

}

nav a {
  font-size: 14px;
  font-family: var(--font-semi-bold);
  line-height: 18px;
  color: var(--muted-color);
  margin: 0 12px;
  transition: var(--transition);
  white-space: nowrap;
}

nav a.active {
  color: var(--text-color);
}

.header-lang-select {
  margin-left: 40px;
  border-radius: 40px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-semi-bold);
  color: var(--main-bg-color);
  cursor: pointer;
  transition: var(--transition);
  border: 1px solid #fff;
}

.header-lang-select:hover {
  border-color: var(--blue);
}

.header-lang-select-value {
  width: 36px;
  height: 36px;
  min-width: 36px;
  position: relative;
  border-radius: 50%;
  transition: var(--transition);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--main-bg-color);
  z-index: 1;
}

.header-lang-select-value:hover {
  background-color: var(--blue);

}

.header-lang-select-hidden {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  padding-top: 38px;
  color: var(--text-color);
  background: var(--main-bg-color);
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  border: 1px solid var(--blue);
  display: none;
}

.header-lang-select.visible {
  border-color: var(--blue);
}

.header-lang-select.visible .header-lang-select-hidden {
  display: block;
}

.header-lang-select.visible .header-lang-select-value {
  background-color: var(--blue);
}

.header-lang-select-hidden-item {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.header-lang-select-hidden-item:hover {
  color: var(--blue);
}

