@media only screen and (max-width: 1279px) {


}

@media only screen and (max-width: 991px) {
  .section-1-bg {
    position: relative;
    top: auto;
    right: auto;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    margin-bottom: -150px;
  }
  .section-1-bg img {
    position: relative;
    left: 80px;
    top: -20px;
    width: 100%;
    min-width: 690px;
    z-index: 1;
  }
  .section-1-top {
    position: relative;
  }
  .section-1-bg-blue {
    top: calc(100%  - 50px);
  }
  .section-1-bg-red {
    top: 100%;
  }
  .section-1-bg-orange {
    top: calc(100% + 200px);
    right: auto;
    left: 80%;
  }
  .section-1 .big-arrow-text {
    margin-top: 40px;
  }
  .section1-item {
    padding-top: 24px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
