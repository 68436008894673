::-webkit-scrollbar, iframe body::-webkit-scrollbar, .body-inside-iframe::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: transparent !important;
  /*border: 1px solid #d5d5d5 !important;*/
}

::-webkit-scrollbar-thumb, iframe body::-webkit-scrollbar-thumb, .body-inside-iframe::-webkit-scrollbar-thumb {
  background-color: var(--border-color) !important;
  border: 2px solid var(--border-color) !important;
}

html {
  scroll-behavior: smooth;
  width: 100vw;
  /*overflow: hidden;*/
}

body {
  overscroll-behavior-y: none;
  position: relative;
  width: 100vw;
  color: var(--text-color);
  font-family: var(--font-refular);
  font-size: 18px;
  line-height: 28px;
  background-color: var(--main-bg-color);
  overflow-x: hidden;
  margin: 0;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  padding: 140px 40px 0 !important;
  flex: 1;
  max-width: 1279px;
}

.no-scroll {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
}

h1 {
  font-size: 80px;
  line-height: 98px;
  text-transform: uppercase;
  max-width: 880px;
}

h2 {
  font-size: 60px;
  line-height: 73px;
  text-transform: uppercase;
  margin-bottom: 80px;
}

h3 {
  font-size: 36px;
  line-height: 48px;
}

.btn {
  height: 40px;
  border-radius: 40px;
  transition: var(--transition);
  font-family: var(--font-semi-bold);
  position: relative;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  padding-left: 32px;
  padding-right: 32px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.btn:active {
  box-shadow: none;
}

.btn-lg {
  height: 64px;
  font-size: 18px;
  line-height: 28px;
}

.btn-primary {
  box-shadow: none;
  border-color: var(--tint-color);
  background-color: var(--tint-color);
}

.btn-primary:focus {
  box-shadow: none;
  border-color: var(--tint-color);
  background-color: var(--tint-color);
}

.btn-primary:active:focus {
  border-color: #DC6F0A;
  background-color: #DC6F0A;
  box-shadow: none;
}


.btn-primary:hover {
  border-color: #FF922D;
  background-color: #FF922D;
}

.btn-outline-primary {
  color: var(--tint-color);
  box-shadow: none;
  border-color: var(--tint-color);
  background-color: transparent;
}


.btn-outline-primary:active:focus {
  color: var(--tint-color);
  border-color: var(--tint-color);
  background-color: rgba(255, 127, 8, .05);
  box-shadow: none;
}

.btn-outline-primary:focus {
  color: var(--tint-color);
  box-shadow: none;
  border-color: var(--tint-color);
  background-color: transparent;
}

.btn-outline-primary:hover {
  color: var(--tint-color);
  border-color: var(--tint-color);
  background-color: rgba(255, 127, 8, .15);
}


.btn-outline-secondary {
  color: var(--blue);
  box-shadow: none;
  border-color: var(--blue);
  background-color: transparent;
}


.btn-outline-secondary:active:focus {
  color: var(--blue);
  border-color: var(--blue);
  background-color: rgba(48, 255, 243, .05);
  box-shadow: none;
}

.btn-outline-secondary:focus {
  color: var(--blue);
  box-shadow: none;
  border-color: var(--blue);
  background-color: transparent;
}

.btn-outline-secondary:hover {
  color: var(--blue);
  border-color: var(--blue);
  background-color: rgba(48, 255, 243, .15)
}


.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  background: rgba(255, 255, 255, .1);
  color: rgba(255, 255, 255, .1);
}

.btn > div {
  z-index: 1;
  position: relative;
}

ul {
  margin: 0;
  padding-left: 20px;
}

ul li {
  padding-bottom: 8px;
}

p {
  margin-bottom: 0;
  letter-spacing: 0.01em;
}

a {
  color: var(--muted-color);
  text-decoration: none;
  outline: none;
  transition: var(--transition);
}

a:hover {
  outline: none;
  color: var(--text-color);
}

a:active {
  outline: none;
  color: var(--tint-color);
}

.text-bold {
  font-family: var(--font-bold);
}

.text-bold-16 {
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
}

.text-muted {
  color: var(--muted-color);
}

.text-semiBold {
  font-family: var(--font-semi-bold);
}

.text-muted-14 {
  color: var(--muted-color);
  font-size: 14px;
  line-height: 18px;
}

.text-14 {
  font-size: 14px;
  line-height: 18px;
}

.text-tint {
  color: var(--tint-color);
}


.cur-pointer {
  cursor: pointer;
}

section {
  padding-top: 40px;
  padding-bottom: 160px;
  border-bottom: 1px solid #fff;
}

section:last-child {
  border-bottom: none;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-80 {
  margin-bottom: 80px;
}

.action-el {
  cursor: pointer;
  transition: var(--transition);
}

.action-el:hover {
  color: var(--tint-color);
}
.big-arrow-text {
  display: inline-block;
}
.big-arrow-text span {
  font-size: 60px;
  line-height: 80px;
  /* or 133% */

  text-transform: uppercase;
  text-decoration: underline;
  color: #FFFFFF;
  opacity: 0.5;
  transition: var(--transition);
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 3px;
  text-underline-offset: 5px;
}

.big-arrow-text:hover span {
  opacity: 1;
}

.big-arrow-text:active span {
  opacity: 1;
  color: var(--tint-color);
}

.big-arrow-text div {
  display: inline;
}

.big-arrow-text-icon {
  position: relative;
  left: 10px;
}

.section-left {
  flex: 1;
  margin-right: 40px;
}

.section-right {
  flex: 1;
  margin-left: 40px;
}

.section-title-img img {
  width: 88px;
  margin: 0 20px;
  position: relative;
  bottom: 6px;
}
.Toastify__toast-theme--light {
  background: var(--main-bg-color);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  border-radius: 0;
  border: 1px solid #43454B;
}
.Toastify__progress-bar--error {
  background: var(--danger);
}