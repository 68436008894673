footer {
  padding: 0 40px;
  height: 100px;
  width: 100%;
}

.footer {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #fff;
}
.footer-logo {
  width: 140px;
  min-width: 140px;
}
@media only screen and (max-width: 991px) {
  footer {
    height: auto;
  }
  .footer {
    flex-direction: column;
  }
  .footer .header-separator {
    width: 28px;
    height: 1px;
    margin: 16px 0;
  }
  .footer-logo {
    margin-right: 16px;
  }
}
@media only screen and (max-width: 767px) {
  footer {
    padding: 0 16px;
  }
}