.section-2 {
  position: relative;
}

.section-2:before {
  content: '';
  position: absolute;
  top: -330px;
  left: -700px;
  width: 665px;
  height: 665px;
  border-radius: 50%;
  background: #30FFF3;
  filter: blur(205px);
  opacity: 0.5;
  z-index: -1;
}

.section-2:after {
  content: '';
  position: absolute;
  top: 260px;
  left: -200px;
  width: 436px;
  height: 436px;
  border-radius: 50%;
  background: #FF7F08;
  filter: blur(205px);
  opacity: 0.8;
  z-index: -1;
}

.section-2 h2 {
  display: flex;
  align-items: center;
}
.section-2 h2:after {
  content: '';
  position: absolute;
  top: 100px;
  left: -100px;
  width: 478px;
  height: 478px;
  border-radius: 50%;
  background: #FF7690;
  filter: blur(205px);
  opacity: 0.7;
  z-index: -1;
}
.section-2 h2 img {
  height: 42px;
  margin-left: 16px;
}
.section2-dashboard img {
  width: 100%;
}
.section2-dashboard-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px -20px 0;
}
.section2-dashboard-items .text-semiBold {
  margin: 0 20px;
  position: relative;
}
.section2-dashboard-items .text-semiBold:after {
  content: attr(data-idx);
  position: absolute;
  left: 0;
  top: -50px;
  z-index: -1;
  font-size: 100px;
  line-height: 100px;
  opacity: 0.05;
}
.section2-info div:nth-child(1) {
  margin-bottom: 20px;
}
.section2-info div:nth-child(2) {
  margin-bottom: 80px;
}
.section2-info h3:before {
  content: '';
  position: absolute;
  left: -88px;
  top: 25px;
  height: 2px;
  width: 60px;
  background-color: #fff;
}
.section-2 .section-left h3 {
  margin-top: 40px;
}