.section-1 {
  position: relative;
}

.section-1-bg {
  position: absolute;
  top: -10px;
  right: -175px;
  z-index: -1;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.section-1-bg-blue {
  content: '';
  position: absolute;
  top: 0;
  right: -250px;
  width: 470px;
  height: 470px;
  border-radius: 50%;
  background: #30FFF3;
  filter: blur(205px);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-1-bg-blue:after {
  position: absolute;
  content: '';
  width: 200px;
  height: 200px;
  background: #30FFF3;
  filter: blur(60px);
}
.section-1-bg-orange {
  content: '';
  position: absolute;
  top: 500px;
  right: -200px;
  width: 436px;
  height: 436px;
  border-radius: 50%;
  background: #FF7F08;
  filter: blur(205px);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-1-bg-orange:after {
  position: absolute;
  content: '';
  width: 200px;
  height: 200px;
  background: #FF7F08;
  filter: blur(60px);
}
.section-1-bg-red {
  position: absolute;
  right: 400px;
  width: 478px;
  height: 478px;
  border-radius: 50%;
  background: #FF7690;
  filter: blur(205px);
  opacity: 0.5;
  z-index: -1;
}

.section-1-bg img {
  width: 1131px;
}


.section-1 button {
  min-width: 240px;
}

.section-1 .section-left {
  margin-top: 100px;
}
.section1-item {
  display: flex;
  align-items: center;
  padding-top: 32px;
  margin-top: 60px;
  border-top: 1px solid #fff;
  position: relative;
}
.section1-item img{
  width: 100px;
  margin-right: 40px;
}

.section1-item:before {
  content: '';
  position: absolute;
  width: 76px;
  height: 76px;
  z-index: -1;
  opacity: 0.5;
  filter: blur(25px);
  border-radius: 50%;

}
.section1-item:nth-child(1):before {
  background: #30FFF3;
  left: -30px;
  top: 0;
}
.section1-item:nth-child(2):before {
  background: #FF7F08;
  left: 20px;
  top: 20px;
}
.section1-item:nth-child(3):before {
  background: #FF7690;
  left: -10px;
  top: 30px;
}