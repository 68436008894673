:root {
  --main-bg-color: #141414;
  --text-color: #ffffff;
  --tint-color: #FF7F08;
  --muted-color: rgba(255,255,255,0.5);
  --divider: rgba(255,255,255,0.2);
  --border-color: #ffffff;

  --blue: #30FFF3;
  --danger: #FF2A1D;


  --font-refular: 'Montserrat-Regular', sans-serif;
  --font-medium: 'Montserrat-Medium', sans-serif;
  --font-bold: 'Montserrat-Bold', sans-serif;
  --font-semi-bold: 'Montserrat-SemiBold', sans-serif;

  --transition: all .3s ease;
  --transition-1s: all 1s ease;
}
