.section-4 {
  display: flex;
  position: relative;
}

.section-4-bg {
  position: absolute;
  top: 300px;
  left: -100px;
  opacity: 0.8;
}

.section-4-bg:after {
  content: '';
  position: absolute;
  top: 150px;
  left: 250px;
  width: 470px;
  height: 470px;
  border-radius: 50%;
  background: #30FFF3;
  filter: blur(205px);
  opacity: 0.4;
  z-index: -1;
}

.section-4-bg:before {
  content: '';
  position: absolute;
  top: 100px;
  left: -600px;
  width: 636px;
  height: 636px;
  border-radius: 50%;
  background: #FF7F08;
  filter: blur(205px);
  opacity: 0.8;
  z-index: -1;
}

.section-4-bg-red {
  position: absolute;
  top: -100px;
  left: -200px;
  width: 636px;
  height: 636px;
  border-radius: 50%;
  background: #FF7690;
  filter: blur(205px);
  opacity: 0.6;
  z-index: -1;
}

.section-4-item {
  position: relative;
  padding: 10px 0;
  border-radius: 40px;
  transition: var(--transition);
  color: rgba(255, 255, 255, 0.8);
}

.section-4-item.active {
}

.section-4-item:after {
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--muted-color);
  transition: var(--transition);
}

.section-4-items .section-4-item:last-child:before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--border-color);

  /*transition: var(--transition);*/
}

.section-4-items .section-4-item:first-child:after,
.section-4-item.active:after,
.section-4-item.active:before,
.section-4-item.active + .section-4-item:after {
  opacity: 0;
}

.section-4-item-head {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 30px 40px;
  font-size: 32px;
  line-height: 40px;
  font-family: var(--font-bold);
}

.section-4-item-head:hover h4 {
  color: var(--tint-color)
}

.section-4-item-head h4 {
  flex: 1;
  transition: var(--transition);
  font-family: var(--font-refular);
}

.section-4-item-head h4 {
  flex: 1;
  color: var(--muted-color);
}

.section-4-item-head h4, .section-4-item-head path, .section-4-item-head svg {
  transition: var(--transition);
  stroke: var(--muted-color);
}

.section-4-item.active .section-4-item-head h4, .section-4-item-head:hover h4 {
  color: var(--tint-color);
}

.section-4-item.active .section-4-item-head path, .section-4-item-head:hover path {
  stroke: var(--tint-color);
}

.section-4-item.active .section-4-item-head svg {
  transform: rotate(-90deg);
}

.section-4-item-content {
  padding: 0 40px 30px;
  transform: scale(0);
  opacity: 0;
  max-height: 0;
  margin-top: -40px;
}

.section-4-item.active .section-4-item-content {
  transition: var(--transition);
  transform: scale(1);
  opacity: 1;
  max-height: 200vh;
  display: block;
  margin-top: 0;
}

.section-4-dark_purple {
  position: relative;
  padding: 20px;
  border-radius: 20px;
}

.section-4-dark_purple:after {
  content: '';
  background: var(--main-bg-color);
  opacity: 0.5;
  position: absolute;

}

.section4-footer-text {
  position: absolute;
  bottom: 160px;
  left: 0;
  width: 50%;
  padding-right: 40px;
}