.section-3 {
}

.section-3-img img {
  position: absolute;
  width: calc(100% + 100px);
  height: auto;
  right: -40px;
  bottom: -40px;
}

.section-3-img:before {
  content: '';
  position: absolute;
  bottom: -218px;
  right: -218px;
  width: 436px;
  height: 436px;
  border-radius: 50%;
  background: #FF7F08;
  filter: blur(205px);
  z-index: -1;
}
.section-3-text-arrow {
  margin-bottom: 10px;
}