@media only screen and (max-width: 1279px) {



}

@media only screen and (max-width: 991px) {
  .section4-footer-text {
    position: relative;
    left: auto;
    bottom: auto;
    width: 100%;
    padding-right: 0;
    margin-top: 76px;
  }
  .section-4 {
    flex-direction: column;
  }
  .section-4-items {
    padding-top: 32px;
  }

  .section-4-item {
    padding: 0;
  }

  .section-4-item-head {
    padding: 24px;
  }

  .section-4-item-content {
    padding: 24px;
    padding-top: 0;
  }

  .section-4 {
    padding-bottom: 32px;
  }
  .section-4-bg {
    top: auto;
    bottom: 250px;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .section-4-bg-red {
    width: 156px;
    height: 156px;
    top: auto;
    bottom: auto;
    left: auto;
    filter: blur(70px);
  }
  .section-4-bg:before {
    left: auto;
    top: auto;
    width: 140px;
    height: 140px;
    margin-left: -300px;
    margin-bottom: -50px;
    filter: blur(70px);
  }
  .section-4-bg:after {
    left: auto;
    top: auto;
    width: 140px;
    height: 140px;
    margin-left: 300px;
    margin-bottom: -100px;
    filter: blur(70px);
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
