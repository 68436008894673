@media only screen and (max-width: 1279px) {
  .section2-info h3:before {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .section2-info div:nth-child(1) {
    margin-bottom: 16px;
  }
  .section2-info div:nth-child(2) {
    margin-bottom: 40px;
  }
  .section2-dashboard-items .text-semiBold {
    padding-top: 30px;
  }

  .section-2 h2:after {
    top: 0px;
    left: auto;
    right: 100px;
    width: 228px;
    height: 228px;
  }
  .section-2:before {
    top: 100px;
    left: -100px;
    width: 318px;
    height: 318px;
  }

  .section-2:after {
    top: auto;
    bottom: 100px;
    left: auto;
    right: 0;
    width: 208px;
    height: 208px;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
