@media only screen and (max-width: 1550px) {
  main {
    width: 100vw;
    overflow-x: hidden;
    margin: 0 !important;
    max-width: 100%;
  }

}

@media only screen and (max-width: 1279px) {

  h1 {
    font-size: 48px;
    line-height: 59px;
    max-width: 724px;
  }

  .big-arrow-text span {
    font-size: 40px;
    line-height: 48px;
  }

  .big-arrow-text-icon {
    left: 8px;
  }

  .big-arrow-text-icon svg {
    width: 28px;
    height: 28px;
  }
  .section-left {
    margin-right: 20px;
  }

  .section-right {
    margin-left: 20px;
  }
  main {
    padding-top: 200px !important;
  }

  body {
    font-size: 14px;
    line-height: 22px;
  }
  section {
    padding-bottom: 80px;
  }

  h2 {
    font-size: 40px;
    line-height: 49px;
    margin-bottom: 32px;
  }
  h3 {
    font-size: 24px;
    line-height: 36px;
  }
  .mb-80 {
    margin-bottom: 40px;
  }
  .mb-40 {
    margin-bottom: 20px;
  }
  .mb-20 {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .section-left {
    margin-right: 0;
  }

  .section-right {
    margin-left: 0;
  }


}

@media only screen and (max-width: 767px) {
  main {
    padding: 180px 16px 16px !important;
  }
}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
