@media only screen and (max-width: 1279px) {
  header {
    height: auto;
  }

  .header {
    flex-direction: column;
    padding: 24px 0 16px;
    align-items: center;
  }

  .header-right {
    padding-top: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .header-left {
    flex-direction: column;
  }

  .header-logo {
    margin-right: 16px;
  }

  .header-secondary .header-lang-select {
    margin-left: 16px;
  }

}

@media only screen and (max-width: 767px) {
  header {
    padding: 0 16px;
  }
}

@media only screen and (max-width: 575px) {
  .header-separator {
    margin: 0 16px;
  }
}

@media only screen and (max-width: 374px) {

}