.registration-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background: url("../../assets/images/bg.png") no-repeat center;
  background-position-y: calc(100% + 250px);
  background-size: 100%;
}
.registration-main {
  width: 1280px;
  max-width: 100%;
  padding-top: 180px!important;
  padding-bottom: 40px !important;

}

.registration-separator {
  width: 100%;
  height: 1px;
  background: #fff;
  margin: 20px 0;
}

.registration-main .btn-primary {
  background: url("../../assets/images/arrow.svg") no-repeat calc(100% - 12px) center, var(--tint-color);
  background-size: 12px;
  width: 260px;
  max-width: 100%;
  margin-top: 60px;
}

@media only screen and (max-width: 1279px) {
  .registration-main .mb-40 {
    margin-bottom: 40px;
  }
  .registration-main .mb-80 {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 991px) {
  .registration-separator {
    margin: 16px 0;
  }
}

@media only screen and (max-width: 767px) {
  .registration-main {
    padding-bottom: 16px !important;

  }
  .registration-page {
    background-image: url("../../assets/images/bg_small.png");
  }
  .registration-main .btn-primary {
    width: 100%;
  }
}

@media screen and (min-height: 1280px) and (min-width: 1920px) {
  .registration-page {
    background-position-y: 100% !important;
  }
}

