@media only screen and (max-width: 1279px) {

}

@media only screen and (max-width: 991px) {

  .section-3-img img {
    position: relative;
    width: 100%;
  }

}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {

}

@media only screen and (max-width: 374px) {

}
